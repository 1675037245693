import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { isEqual } from "lodash";
import { cloneElement, FC, ReactNode, useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";
import { Conditions, useGetBrandsQuery } from "../generated/graphql";
import { formatToIndianNumbering, toTitleCase } from "../utils/functions";
import { ClassNames } from "./classes";
import { Dropdown } from "./dropdown";
import { Icons } from "./icons";
import { Loading } from "./loading";
import { Slider } from "./slider";

function getDropdownItems(items: string[]) {
  return items.map(categoryName => ({ id: categoryName, label: toTitleCase(categoryName) }));
}

const categoryDropdownItems = getDropdownItems(["Men", "Women", "Home", "Jewellery", "Beauty"]);
const colorDropdownItems = getDropdownItems(["red", "green", "blue", "yellow", "magenta", "cyan", "black", "white", "multicolored"]);
const sizeDropdownItems = getDropdownItems(["xxs", "xs", "sm", "md", "lg", "xl", "2xl", "3xl", "4xl"]);
const subCategoryDropdownItems = getDropdownItems(["bags", "accessories", "trousers", "sports", "trench-coats", "sweaters", "dupattas", "trend", "kurtas", "blouses", "charms", "jeans", "sweats", "basics", "outerwear", "denim-washes", "dresses-overlays", "kaftans", "shorts", "bangles", "dresses", "joggers", "suits", "jackets", "shoes", "waistcoats", "maternity-wear", "bodysuits", "makeup", "earrings", "tops", "necklaces", "scarves", "perfumes", "cardigans", "jewellery", "rings", "bottoms", "innerwear", "sweatshirts", "knitwear", "fleece", "bracelets", "sarees", "blazers", "best-seller", "chains", "shirts", "co-ord-sets", "loungewear", "t-shirts", "skirts"]);
const occasionDropdownItems = getDropdownItems(["brunches","casual","travelling","weekend-party","workwear","festive","holiday","cocktail-party","wedding-guest","formal-events","date-night","beachwear","resortwear","lounge/at-home","night-out","winterwear","rainy-season","gym/activewear","outdoor-activities","sports-events","music-festivals","seasonal-celebrations","cultural-events","family-gatherings","business-meetings","smart-casual","pool-party","graduation","anniversary-celebrations","streetwear","clubwear","summer-wear","nightwear","bohemian/boho"]);
const fitDropdownItems = getDropdownItems(["regular","slim","relaxed","oversized","tailored","loose","straight","skinny","tapered","athletic","baggy","boxy","high-waisted","low-waisted","cropped","flared","wide-leg","bootcut","fitted","compression","a-line","bodycon","empire waist","dropped shoulder","peplum","hourglass","relaxed straight","boyfriend","girlfriend","curvy","vintage","tailored slim","sculpted","straight-leg","balloon","paperbag"]);
const sleeveDropdownItems = getDropdownItems(["full","long","sleeveless","3/4","half","cap","short","drop-shoulder","cut","bell","puff","bishop"]);
const materialDropdownItems = getDropdownItems(["acrylic","acetate","bamboo","brass","camelina","cashmere","cotton","crepe","cupro","denim","dupion","elastic","elastane","elastomultiester","fleece","flax","fur","georgette","glass","hemp","jute","khadi","knit","lace","leather","linen","lyocell","modal","muslin","nylon","organic","paper","polyamide","polyethylene","polyester","polypropylene","rayon","recycled","rubber","sil"]);
const countryDropdownItems = getDropdownItems(["india","cambodia","morocco","hong-kong","united-nations","marocco","bengladesh","usa","ghanain","europe","myanmar","unknown","mexico","madagascar","egypt","serbia","thailand","singapore","vietnam","brazil","united-states","turkey","india","pakistan","italy","slovakia","indonesia","romania","united-kingdom","spain","georgia","china","marroco","taiwan","sri-lanka","portugal","sweden","uae","bangladesh","philippines","tunisia"]);

export function getFilterConditions(filterProps: ReturnType<typeof useFilter>) {
  return {
    BrandIds: filterProps.brandIds,
    CategoryNames: filterProps.categoryNames,
    SubCategory: filterProps.subCategory,
    Colors: filterProps.colors,
    MaxDiscount: filterProps.maxDiscount,
    MinDiscount: filterProps.minDiscount,
    MaxPrice: filterProps.maxPrice,
    MinPrice: filterProps.minPrice,
    Sizes: filterProps.sizes,
    Materials: filterProps.materials,
    Fit: filterProps.fit,
    Sleeves: filterProps.sleeves,
    Occasion: filterProps.occasion,
    Country: filterProps.country,
  };
}

export const useFilter = () => {
  const { data: brands, loading } = useGetBrandsQuery();
  const [brandIds, setBrandIds] = useState<string[]>([]);
  const [categoryNames, setCategoryNames] = useState<string[]>([]);
  const [colors, setColors] = useState<string[]>([]);
  const [[minPrice, maxPrice], setPrice] = useState<[number, number]>([0, 100000]);
  const [[minDiscount, maxDiscount], setDiscount] = useState<[number, number]>([0, 20000]);
  const [sizes, setSizes] = useState<string[]>([]);
  const [subCategory, setSubCategory] = useState<string[]>([]);
  const [materials, setMaterials] = useState<string[]>([]);
  const [fit, setFit] = useState<string[]>([]);
  const [sleeves, setSleeves] = useState<string[]>([]);
  const [occasion, setOccasion] = useState<string[]>([]);
  const [country, setCountry] = useState<string[]>([]);
  const [conditions, setConditions] = useState<Conditions>({
    BrandIds: undefined,
    CategoryNames: undefined,
    SubCategory: undefined,
    Colors: undefined,
    MinDiscount: minDiscount,
    MaxDiscount: maxDiscount,
    MinPrice: minPrice,
    MaxPrice: maxPrice,
    Sizes: undefined,
    Materials: undefined,
    Fit: undefined,
    Sleeves: undefined,
    Occasion: undefined,
    Country: undefined,
  });

  const brandItems = useMemo(() => brands?.Brand.map(brand => ({ id: brand.Id, label: toTitleCase(brand.Name) })) ?? [], [brands?.Brand]);
  const enableApply = useMemo(() => {
    return !isEqual(conditions, {
      BrandIds: brandIds,
      CategoryNames: categoryNames,
      SubCategory: subCategory,
      Colors: colors,
      MaxDiscount: maxDiscount,
      MinDiscount: minDiscount,
      MaxPrice: maxPrice,
      MinPrice: minPrice,
      Sizes: sizes,
      Materials: materials,
      Fit: fit,
      Sleeves: sleeves,
      Occasion: occasion,
      Country: country,
    });
  }, [conditions, brandIds, categoryNames, subCategory, colors, maxDiscount, minDiscount, maxPrice, minPrice, sizes, country, fit, materials, occasion, sleeves]);

  return {
        loading,
        brandIds, brandItems, categoryNames, subCategory, colors, minPrice, maxPrice, minDiscount, maxDiscount, sizes, materials, fit, sleeves, occasion, country, conditions, 
        setBrandIds, setCategoryNames, setSubCategory, setColors, setPrice, setDiscount, setSizes, setMaterials, setFit, setSleeves, setOccasion, setCountry, setConditions,
        enableApply,
    }
}

const HoverableCollapse: FC<{ collapsed: ReactNode, children: ReactNode }> = ({ collapsed, children }) => {
  const [hover, setHover] = useState(false);
  return <AnimatePresence mode="wait">
    <motion.div className="transition-all border rounded-lg border-white/10 px-3 py-1 bg-white/5 hover:bg-white/10"
      onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      {
        hover
        ? <motion.div key="children" className="flex items-center gap-2"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ x: { type: "spring", stiffness: 100 }, duration: 0.8, delay: 0.2, }}>
          {children}
        </motion.div>
        : <motion.div key="collapsed" className="flex items-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ x: { type: "spring", stiffness: 100 }, duration: 0.8, delay: 0.2, }}>
          {collapsed}
        </motion.div>
      }
    </motion.div>
  </AnimatePresence>
}

export const Filter: FC<ReturnType<typeof useFilter> & { ignore?: "brandIds"[], onClick?: () => void }> = ({
        loading,
        brandItems,
        brandIds, setBrandIds,
        categoryNames, setCategoryNames,
        subCategory, setSubCategory,
        minPrice, maxPrice, setPrice,
        minDiscount, maxDiscount, setDiscount,
        colors, setColors,
        sizes, setSizes,
        materials, setMaterials,
        fit, setFit,
        sleeves, setSleeves,
        occasion, setOccasion,
        country, setCountry,
        ignore = [],
        onClick,
        enableApply,
}) => {
    return <div className="flex gap-2 items-center">
    {
      !ignore.includes("brandIds") && <Dropdown className="text-sm border px-3 py-1 border-white/10 rounded-lg bg-white/5" placeholder="Brand" selectedId={brandIds} items={brandItems} onChange={setBrandIds} multiple={true} />
    }
    <HoverableCollapse collapsed={<div className={classNames(ClassNames.Button, "text-sm")}>Category ({categoryNames.length+subCategory.length})</div>}>
      <Dropdown className="text-sm" placeholder="Category" items={categoryDropdownItems} selectedId={categoryNames} onChange={setCategoryNames} multiple={true} />
      <Dropdown className="text-sm" placeholder="Sub-Category" items={subCategoryDropdownItems} selectedId={subCategory} onChange={setSubCategory} multiple={true} />
    </HoverableCollapse>
    <HoverableCollapse collapsed={<div className={classNames(ClassNames.Button, "text-sm")}>Pricing ({minPrice}-{formatToIndianNumbering(maxPrice)})</div>}>
      <Slider className="text-sm" placeholder="Price" min={0} max={100000} onChange={setPrice} step={1000} />
      <Slider className="text-sm" placeholder="Discount" min={0} max={20000} onChange={setDiscount} step={1000} />
    </HoverableCollapse>
    <HoverableCollapse collapsed={<div className={classNames(ClassNames.Button, "text-sm")}>Details ({colors.length + sizes.length + materials.length + fit.length + sleeves.length})</div>}>
      <Dropdown className="text-sm" placeholder="Color" items={colorDropdownItems} selectedId={colors} onChange={setColors} multiple={true} />
      <Dropdown className="text-sm" placeholder="Sizes" items={sizeDropdownItems} selectedId={sizes} onChange={setSizes} multiple={true} />
      <Dropdown className="text-sm" placeholder="Material" items={materialDropdownItems} selectedId={materials} onChange={setMaterials} multiple={true} />
      <Dropdown className="text-sm" placeholder="Fit" items={fitDropdownItems} selectedId={fit} onChange={setFit} multiple={true} />
      <Dropdown className="text-sm" placeholder="Sleeve" items={sleeveDropdownItems} selectedId={sleeves} onChange={setSleeves} multiple={true} />
      <Dropdown className="text-sm" placeholder="Occasion" items={occasionDropdownItems} selectedId={occasion} onChange={setOccasion} multiple={true} />
    </HoverableCollapse>
    <Dropdown className="text-sm border px-3 py-1 border-white/10 rounded-lg bg-white/5" placeholder="Country" items={countryDropdownItems} selectedId={country} onChange={setCountry} multiple={true} />
    {
        onClick != null && loading
        ?  <div className="h-fit w-[50px]">
            <Loading className="h-8" />
        </div>
        : <div className={twMerge(classNames(ClassNames.Button, "border dark:border-green-500/10 rounded-lg px-3 py-1 dark:text-green-500 transition-all text-sm", {
            "opacity-100": enableApply,
            "opacity-25 pointer-events-none": !enableApply,
        }))} onClick={onClick}>
            Apply
            {cloneElement(Icons.CheckCircle, {
            className: "w-4 h-4",
            })}
        </div>
    }
  </div>
}