import { values } from "lodash";
import { ReactNode } from "react";
import { LogoutPage } from "../pages/auth/logout";
import { BrandAnalyticsPage } from "../pages/brand/brand-analytics";
import { BrandPage } from "../pages/brand/brands";
import { DashboardPage } from "../pages/dashboard/dashboard";
import { CompareProductPage } from "../pages/product/compare-product";
import { ProductPage } from "../pages/product/products";

export type IInternalRoute = {
    name: string;
    path: string;
    component: ReactNode;
    public?: boolean;
}

export const InternalRoutes = {
    Dashboard: {
        name: "Home",
        path: "/",
        component: <DashboardPage />,
    },
    Brands: {
        Brands: {
            name: "Brand",
            path: "/brand",
            component: <BrandPage />,
        },
        Analytics: {
            name: "Brand analytics",
            path: "/brand/analytics",
            component: <BrandAnalyticsPage />,
        }
    },
    Product: {
        Product: {
            name: "Product",
            path: "/product",
            component: <ProductPage />,
        },
        Compare: {
            name: "Compare",
            path: "/compare",
            component: <CompareProductPage />,
        },
    },
    Logout: {
        name: "Logout",
        path: "/logout",
        component: <LogoutPage />,
    },
}

export const getRoutes = (): IInternalRoute[] => {
    const allRoutes: IInternalRoute[] = [];
    const currentRoutes = values(InternalRoutes);
    while (currentRoutes.length > 0) {
        const currentRoute = currentRoutes.shift();
        if (currentRoute == null) {
            continue;
        }
        if ("path" in currentRoute) {
            allRoutes.push(currentRoute);
            continue;
        }
        currentRoutes.push(...values((currentRoute)));
    }
    return allRoutes;
}