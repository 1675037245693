
export const FILL_COLORS = [
    'fill-sky-400 dark:fill-sky-700', 'fill-rose-400 dark:fill-rose-700', 'fill-teal-400 dark:fill-teal-700', 
    'fill-orange-400 dark:fill-orange-700', 'fill-lime-400 dark:fill-lime-700', 'fill-purple-400 dark:fill-purple-700',
    'fill-yellow-400 dark:fill-yellow-700', 'fill-green-400 dark:fill-green-700', 'fill-indigo-400 dark:fill-indigo-700',
    'fill-pink-400 dark:fill-pink-700', 'fill-red-400 dark:fill-red-700', 'fill-blue-400 dark:fill-blue-700',
    'fill-cyan-400 dark:fill-cyan-700', 'fill-amber-400 dark:fill-amber-700', 'fill-fuchsia-400 dark:fill-fuchsia-700',
    'fill-violet-400 dark:fill-violet-700', 'fill-gray-400 dark:fill-gray-700', 'fill-stone-400 dark:fill-stone-700',
    'fill-emerald-400 dark:fill-emerald-700', 'fill-light-blue-400 dark:fill-light-blue-700', 
    'fill-warm-gray-400 dark:fill-warm-gray-700', 'fill-cool-gray-400 dark:fill-cool-gray-700',
    'fill-true-gray-400 dark:fill-true-gray-700', 'fill-neutral-400 dark:fill-neutral-700',
    'fill-blue-gray-400 dark:fill-blue-gray-700', 'fill-rose-500 dark:fill-rose-800', 'fill-orange-500 dark:fill-orange-800',
    'fill-yellow-500 dark:fill-yellow-800', 'fill-green-500 dark:fill-green-800', 'fill-cyan-500 dark:fill-cyan-800'
];

export const BG_COLORS = [
    'bg-sky-400 dark:bg-sky-700', 'bg-rose-400 dark:bg-rose-700', 'bg-teal-400 dark:bg-teal-700', 
    'bg-orange-400 dark:bg-orange-700', 'bg-lime-400 dark:bg-lime-700', 'bg-purple-400 dark:bg-purple-700',
    'bg-yellow-400 dark:bg-yellow-700', 'bg-green-400 dark:bg-green-700', 'bg-indigo-400 dark:bg-indigo-700',
    'bg-pink-400 dark:bg-pink-700', 'bg-red-400 dark:bg-red-700', 'bg-blue-400 dark:bg-blue-700',
    'bg-cyan-400 dark:bg-cyan-700', 'bg-amber-400 dark:bg-amber-700', 'bg-fuchsia-400 dark:bg-fuchsia-700',
    'bg-violet-400 dark:bg-violet-700', 'bg-gray-400 dark:bg-gray-700', 'bg-stone-400 dark:bg-stone-700',
    'bg-emerald-400 dark:bg-emerald-700', 'bg-light-blue-400 dark:bg-light-blue-700', 
    'bg-warm-gray-400 dark:bg-warm-gray-700', 'bg-cool-gray-400 dark:bg-cool-gray-700',
    'bg-true-gray-400 dark:bg-true-gray-700', 'bg-neutral-400 dark:bg-neutral-700',
    'bg-blue-gray-400 dark:bg-blue-gray-700', 'bg-rose-500 dark:bg-rose-800', 'bg-orange-500 dark:bg-orange-800',
    'bg-yellow-500 dark:bg-yellow-800', 'bg-green-500 dark:bg-green-800', 'bg-cyan-500 dark:bg-cyan-800'
];