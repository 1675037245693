import classNames from "classnames";
import { AnimatePresence, Reorder, useDragControls } from "framer-motion";
import { cloneElement, FC, useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PaddedPieChart } from "../../components/charts/pie-chart";
import { VerticalDistributionChart } from "../../components/charts/vertical-distribution-chart";
import { ClassNames } from "../../components/classes";
import { Filter, getFilterConditions, useFilter } from "../../components/filter";
import { Icons } from "../../components/icons";
import { Loading } from "../../components/loading";
import { InternalPage } from "../../components/page";
import { InternalRoutes } from "../../config/routes";
import { AnalyticsType, Brand, Conditions, useGetBrandAnalyticsQuery, useGetBrandsQuery } from "../../generated/graphql";
import { GlobalActions } from "../../store/global";
import { useAppSelector } from "../../store/hooks";
import { formatNumberWithK, isNumeric, toTitleCase } from "../../utils/functions";
import { SearchBrands } from "./search-brands";
import { QuartileChart } from "../../components/charts/quartile-chart";

const BrandCard: FC<{ item: string, brandMap: Record<string, Brand>, conditions: Conditions }> = ({ item, brandMap, conditions }) => {
    const controls = useDragControls();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const brand = useMemo(() => brandMap[item], [brandMap, item]);

    const { data: analytics, loading } = useGetBrandAnalyticsQuery({
        variables: {
          brandId: brand?.Id,
          conditions,
        },
      });

    const handleDeleteBrand = useCallback((product: Brand) => {
        dispatch(GlobalActions.removeComparingBrand({ id: product.Id }));
    }, [dispatch]);

    const noDataFound = useMemo(() => {
        return analytics?.BrandAnalytics != null && analytics.BrandAnalytics.find(analytics => analytics.Title === "Total Products")?.Highlight?.Value === "0";
    }, [analytics?.BrandAnalytics]);

    return <Reorder.Item key={item} value={item} dragListener={false} dragControls={controls} className={classNames(ClassNames.Card, "relative min-w-[300px] w-[300px] group/product justify-center items-center")}
        variants={{
            open: { opacity: 1, width: "300px", transition: { y: { stiffness: 1000, velocity: -100 }} },
            close: { opacity: 0, width: "0px" },
        }}>
        <div className={classNames(ClassNames.Card, "absolute -left-2 -top-2 transition-all z-10 justify-center items-center cursor-pointer px-2 py-[2px] hover:scale-110")}
            onClick={() => navigate(InternalRoutes.Brands.Brands.path, {
                state: {
                  brand,
                }
            })}>
            <div className={classNames(ClassNames.Text, "text-xs")}>{toTitleCase(brand.Name)}</div>
        </div>
        <div className="flex flex-col h-full w-full overflow-y-scroll">
            {
                loading
                ? <div className="h-full w-full flex justify-center items-center">
                    <div className="h-fit w-[50px]">
                        <Loading className="h-8" />
                    </div>
                </div>
                : noDataFound
                    ? <div className="flex flex-col w-full h-full justify-center items-center gap-4">
                        {cloneElement(Icons.CubeTransparent, {
                            className: "w-16 h-16 stroke-black/50 dark:stroke-white/50",
                        })}
                        <div className={classNames(ClassNames.Text, "text-sm")}>
                            No products found
                        </div>
                    </div>
                    : analytics?.BrandAnalytics.map((analytic, index) => (
                    <div className={classNames("flex w-full py-3", {
                        "bg-black/5 dark:bg-white/5": index % 2 === 0,
                        "pt-4": index === 0,
                    })}>
                        {
                            analytic.Type === AnalyticsType.Highlight && analytic.Highlight != null &&
                            <div key={analytic.Title} className="flex items-center justify-between w-full px-4">
                                <div className={classNames(ClassNames.Text, "text-sm font-bold")}>{analytic.Title}</div>
                                <div className={classNames(ClassNames.Text, "text-sm")}>
                                    {isNumeric(analytic.Highlight.Value) ? formatNumberWithK(Number.parseFloat(analytic.Highlight.Value)) : analytic.Highlight?.Value}
                                </div>
                            </div>
                        }
                        {
                            analytic.Type === AnalyticsType.PieChart && analytic.PieChart != null &&
                            <div key={analytic.Title} className="flex flex-col justify-center items-center w-full px-4">
                                <div className={classNames(ClassNames.Text, "text-sm font-bold")}>{analytic.Title}</div>
                                <PaddedPieChart height={150} width={200} data={analytic.PieChart.Labels.map((name, i) => ({ name, value: analytic.PieChart?.Values[i] ?? 0 })) ?? []} />
                            </div>
                        }
                        {
                            analytic.Type === AnalyticsType.VerticalDistributionChart && analytic.PieChart != null &&
                            <div key={analytic.Title} className="flex flex-col justify-center items-center w-full">
                                <div className={classNames(ClassNames.Text, "text-sm font-bold")}>{analytic.Title}</div>
                                <VerticalDistributionChart data={analytic.PieChart.Labels?.map((name, i) => ({ name, value: analytic.PieChart?.Values[i] ?? 0 })) ?? []} />
                            </div>
                        }
                        {
                            analytic.Type === AnalyticsType.QuartileChart && analytic.Quartile != null &&
                            <div key={analytic.Title} className="flex flex-col justify-center items-center w-full">
                                <div className={classNames(ClassNames.Text, "text-sm font-bold")}>{analytic.Title}</div>
                                <QuartileChart min={analytic.Quartile.Min} max={analytic.Quartile.Max} median={analytic.Quartile.Median} average={analytic.Quartile.Average}
                                    lowerQuartile={analytic.Quartile.FirstQuartile} upperQuartile={analytic.Quartile.ThirdQuartile} />
                            </div>
                        }
                    </div>
                ))
            }
        </div>
        <div className="absolute right-0 -top-4 opacity-0 group-hover/product:opacity-100 z-10 flex justify-center items-center cursor-pointer gap-2">
            <div className="p-2 bg-black/20 dark:bg-white/20 shadow-lg rounded-full backdrop-blur-lg transition-all hover:scale-110" onPointerDown={(e) => controls.start(e)}>
                {cloneElement(Icons.LeftRightArrow, {
                    className: "h-4 w-4 stroke-white rotate-90",
                })}
            </div>
            <div className="p-2 bg-red-400 dark:bg-red-500 shadow-lg rounded-full backdrop-blur-lg transition-all hover:scale-110" onClick={() => handleDeleteBrand(brandMap[item])}>
                {cloneElement(Icons.Delete, {
                    className: "h-4 w-4 stroke-white",
                })}
            </div>
        </div>
    </Reorder.Item>
}

export const BrandAnalyticsPage: FC = () => {
    const [shouldSearch, setShouldSearch] = useState(false);
    const { data: brands } = useGetBrandsQuery();
    const dispatch = useDispatch();
    const comparingBrands = useAppSelector(state => state.global.comparingBrands);
    const filterProps = useFilter();
    
    const handleSearchBrandClick = useCallback((brand: Brand) => {
        dispatch(GlobalActions.addComparingBrand(brand));
        setShouldSearch(false);
    }, [dispatch]);

    const brandMap = useMemo(() => {
        return comparingBrands.reduce((all, one) => {
            all[one.Id] = one;
            return all;
        }, {} as Record<string, Brand>)
    }, [comparingBrands]);

    const { items, setItems } = useMemo(() => {
        return {
            items: comparingBrands.map(brand => brand.Id),
            setItems: (items: string[]) => {
                dispatch(GlobalActions.setAllComparingBrands({
                    brands: items.map(item => brandMap[item]),
                }));
            }
        }
    }, [brandMap, comparingBrands, dispatch]);

    const handleQuery = useCallback(() => {
        filterProps.setConditions(getFilterConditions(filterProps));
    }, [filterProps]);

    return <InternalPage routes={[InternalRoutes.Dashboard, InternalRoutes.Brands.Analytics]}>
        <div className="flex flex-col items-start h-full w-full">
            <div className="flex justify-between w-full mb-4">
                <div className={classNames(ClassNames.Title, "text-2xl")}>Brand analytics</div>
                <Filter {...filterProps} onClick={handleQuery} />
            </div>
            { shouldSearch && <SearchBrands brands={brands?.Brand ?? []} show={shouldSearch} onClose={() => setShouldSearch(false)} onClick={handleSearchBrandClick} /> }
            <div className="flex gap-4 h-[calc(100vh-100px)]">
                <AnimatePresence mode="sync">
                    <div className={classNames(ClassNames.Card, "relative min-w-[150px] my-4 group/product justify-center items-center cursor-pointer")} onClick={() => setShouldSearch(true)}>
                        <div className={classNames(ClassNames.Text, "flex flex-col gap-4 items-center text-sm")}>
                            {cloneElement(Icons.Add, {
                                className: "w-16 h-16 group-hover/product:scale-110 transition-all",
                            })}
                            Add brand
                        </div>
                    </div>
                    <Reorder.Group axis="x" values={items} onReorder={setItems} className="flex h-full gap-4 max-w-[86vw] overflow-x-scroll p-4"
                        variants={{
                            open: { opacity: 1, transition: { staggerChildren: 0.2, delayChildren: 0.05 }},
                            close: { opacity: 0, transition: { staggerChildren: 0.05, staggerDirection: -1 }}
                        }} animate={comparingBrands.length > 0 ? "open" : "close"}>
                        {
                            items.map(item => (
                                <BrandCard key={item} item={item} brandMap={brandMap} conditions={filterProps.conditions} />
                            ))
                        }
                    </Reorder.Group>
                </AnimatePresence>
            </div>
        </div>
    </InternalPage>
}