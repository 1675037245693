import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';
import { ClassNames } from '../classes';
import { BG_COLORS, FILL_COLORS } from './common';


type IPaddedPieChartProps = {
  height: number;
  width: number;
  data: { name: string; value: number }[];
};

const Portal: FC<{ children: React.ReactNode }> = ({ children }) => {
  return ReactDOM.createPortal(children, document.querySelector("#app-container")!);
};

export const PaddedPieChart: FC<IPaddedPieChartProps> = ({ height, width, data }) => {
  const [hoveringCell, setHoveringCell] = useState<string>();
  const [active, setActive] = useState(false);

  const handleOpen = useCallback(() => {
    setActive(true);
  }, []);

  const handleClose = useCallback(() => {
    setActive(false);
  }, []);

  useEffect(() => {
    if (active) {
      (document.querySelector("#app-container") as HTMLElement).style.overflow = 'hidden';
    } else {
      (document.querySelector("#app-container") as HTMLElement).style.overflow = 'auto';
    }
    return () => {
      (document.querySelector("#app-container") as HTMLElement).style.overflow = 'auto';
    };
  }, [active]);

  const entireHeight = window.innerHeight * 0.75;
  const entireWidth = window.innerWidth * 0.75;

  return (
    <>
      <AnimatePresence>
        {active && (
          <Portal>
            <motion.div
              className="fixed inset-0 bg-black bg-opacity-50 z-50 backdrop-blur-md"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={handleClose}
            />
            <motion.div
              className="fixed inset-0 flex justify-center items-center z-50"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.4, ease: 'easeInOut' }}
              onClick={handleClose}
            >
                <div className="flex justify-center items-center">
                <PieChart
                  className="relative"
                  height={entireHeight}
                  width={entireWidth}
                  onClick={handleClose}
                > 
                  <Tooltip content={(data) => (<div className={ClassNames.ChartTooltip}>
                    {data.payload?.at(0)?.name}
                  </div>)} />
                  <Pie className="my-[50px]" data={data} innerRadius={entireHeight/3} outerRadius={entireWidth/4.2} fill="#8884d8" paddingAngle={1} dataKey="value">
                    {data.map((data, index) => (
                      <Cell
                        className={classNames('stroke-none', FILL_COLORS[index % FILL_COLORS.length])}
                        onMouseEnter={() => setHoveringCell(data.name)}
                        onMouseLeave={() => setHoveringCell(undefined)}
                        key={`cell-${index}`}
                      />
                    ))}
                  </Pie>
                </PieChart>
                <div className="ml-4 flex flex-col items-start text-white space-y-1 w-[250px] h-[80vh] overflow-y-auto">
                  {data.map((entry, index) => (
                    <div key={`legend-item-${index}`} className={classNames("flex items-center space-x-2 transition-all px-4 w-full py-1", {
                      "bg-white/10 rounded-xl scale-105": entry.name === hoveringCell, 
                    })}>
                      <div className={classNames("w-8 h-4 rounded-2xl", BG_COLORS[index % BG_COLORS.length])} />
                      <span className='w-full'>{entry.name}</span>
                      <span>({entry.value})</span>
                    </div>
                  ))}
                </div>
              </div>
            </motion.div>
          </Portal>
        )}
      </AnimatePresence>
      <PieChart height={height} width={width} onClick={handleOpen} className="transition-all !cursor-pointer hover:scale-105">
        <Pie data={data} innerRadius={height/3} outerRadius={width/3.2} fill="#8884d8" paddingAngle={1} dataKey="value">
          {data.map((_, index) => (
            <Cell className={classNames('stroke-none', FILL_COLORS[index % FILL_COLORS.length])} key={`cell-${index}`} />
          ))}
        </Pie>
        <Tooltip content={(data) => (<div className={ClassNames.ChartTooltip}>
          {data.payload?.at(0)?.name}
        </div>)} />
      </PieChart>
    </>
  );
};
