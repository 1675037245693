import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type Analytics = {
  __typename?: 'Analytics';
  Highlight?: Maybe<HighlightAnalytics>;
  PieChart?: Maybe<PieChartAnalytics>;
  Quartile?: Maybe<QuartileAnalytics>;
  SubTitle?: Maybe<Scalars['String']['output']>;
  Title: Scalars['String']['output'];
  Type: AnalyticsType;
};

export enum AnalyticsType {
  Highlight = 'Highlight',
  PieChart = 'PieChart',
  QuartileChart = 'QuartileChart',
  VerticalDistributionChart = 'VerticalDistributionChart'
}

export type Brand = {
  __typename?: 'Brand';
  Id: Scalars['String']['output'];
  Name: Scalars['String']['output'];
};

export type Conditions = {
  BrandIds?: InputMaybe<Array<Scalars['String']['input']>>;
  CategoryNames?: InputMaybe<Array<Scalars['String']['input']>>;
  Colors?: InputMaybe<Array<Scalars['String']['input']>>;
  Country?: InputMaybe<Array<Scalars['String']['input']>>;
  Fit?: InputMaybe<Array<Scalars['String']['input']>>;
  Materials?: InputMaybe<Array<Scalars['String']['input']>>;
  MaxDiscount?: InputMaybe<Scalars['Int']['input']>;
  MaxPrice?: InputMaybe<Scalars['Int']['input']>;
  MinDiscount?: InputMaybe<Scalars['Int']['input']>;
  MinPrice?: InputMaybe<Scalars['Int']['input']>;
  Occasion?: InputMaybe<Array<Scalars['String']['input']>>;
  Sizes?: InputMaybe<Array<Scalars['String']['input']>>;
  Sleeves?: InputMaybe<Array<Scalars['String']['input']>>;
  SubCategory?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type HighlightAnalytics = {
  __typename?: 'HighlightAnalytics';
  Value: Scalars['String']['output'];
};

export type LoginCredentials = {
  Token: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  CreateReport?: Maybe<Scalars['Boolean']['output']>;
};

export type PieChartAnalytics = {
  __typename?: 'PieChartAnalytics';
  Labels: Array<Scalars['String']['output']>;
  Values: Array<Scalars['Float']['output']>;
};

export type Product = {
  __typename?: 'Product';
  Brand?: Maybe<Brand>;
  Composition: Scalars['String']['output'];
  Description: Scalars['String']['output'];
  Id: Scalars['String']['output'];
  Labels: Array<Scalars['String']['output']>;
  Name: Scalars['String']['output'];
  SKUs: Array<ProductSku>;
  SourceId: Scalars['String']['output'];
  Url: Scalars['String']['output'];
};

export type ProductSku = {
  __typename?: 'ProductSKU';
  Discount: Scalars['Float']['output'];
  DiscountPercent: Scalars['Float']['output'];
  Id: Scalars['String']['output'];
  Images?: Maybe<Array<ProductSkuImage>>;
  OldPrice: Scalars['Float']['output'];
  Price: Scalars['Float']['output'];
  Sizes?: Maybe<Array<ProductSkuSize>>;
  SourceId: Scalars['String']['output'];
};

export type ProductSkuImage = {
  __typename?: 'ProductSKUImage';
  Color: Scalars['String']['output'];
  Url: Scalars['String']['output'];
};

export type ProductSkuSize = {
  __typename?: 'ProductSKUSize';
  ComingSoon: Scalars['Boolean']['output'];
  Size: Scalars['String']['output'];
};

export type QuartileAnalytics = {
  __typename?: 'QuartileAnalytics';
  Average: Scalars['Float']['output'];
  FirstQuartile: Scalars['Float']['output'];
  Max: Scalars['Float']['output'];
  Median: Scalars['Float']['output'];
  Min: Scalars['Float']['output'];
  ThirdQuartile: Scalars['Float']['output'];
};

export type Query = {
  __typename?: 'Query';
  Brand: Array<Brand>;
  BrandAnalytics: Array<Analytics>;
  BrandProducts: Array<Product>;
  OverallAnalytics: Array<Analytics>;
  Products: Array<Product>;
  Version: Scalars['String']['output'];
};


export type QueryBrandAnalyticsArgs = {
  brandId: Scalars['String']['input'];
  conditions: Conditions;
};


export type QueryBrandProductsArgs = {
  brandId: Scalars['String']['input'];
  conditions: Conditions;
  pageOffset: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type QueryProductsArgs = {
  conditions: Conditions;
  pageOffset: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};

export type StatusResponse = {
  __typename?: 'StatusResponse';
  Status: Scalars['Boolean']['output'];
};

export type GetBrandAnalyticsQueryVariables = Exact<{
  brandId: Scalars['String']['input'];
  conditions: Conditions;
}>;


export type GetBrandAnalyticsQuery = { __typename?: 'Query', BrandAnalytics: Array<{ __typename?: 'Analytics', Type: AnalyticsType, Title: string, SubTitle?: string | null, Highlight?: { __typename?: 'HighlightAnalytics', Value: string } | null, PieChart?: { __typename?: 'PieChartAnalytics', Values: Array<number>, Labels: Array<string> } | null, Quartile?: { __typename?: 'QuartileAnalytics', Min: number, Max: number, Average: number, Median: number, FirstQuartile: number, ThirdQuartile: number } | null }> };

export type GetBrandProductsQueryVariables = Exact<{
  brandId: Scalars['String']['input'];
  pageSize: Scalars['Int']['input'];
  pageOffset: Scalars['Int']['input'];
  conditions: Conditions;
}>;


export type GetBrandProductsQuery = { __typename?: 'Query', BrandProducts: Array<{ __typename?: 'Product', Id: string, Name: string, SourceId: string, Description: string, Composition: string, Url: string, Labels: Array<string>, SKUs: Array<{ __typename?: 'ProductSKU', Id: string, SourceId: string, Price: number, OldPrice: number, Discount: number, Sizes?: Array<{ __typename?: 'ProductSKUSize', Size: string, ComingSoon: boolean }> | null, Images?: Array<{ __typename?: 'ProductSKUImage', Url: string, Color: string }> | null }> }> };

export type GetBrandsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBrandsQuery = { __typename?: 'Query', Brand: Array<{ __typename?: 'Brand', Id: string, Name: string }> };

export type GetOverallAnalyticsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOverallAnalyticsQuery = { __typename?: 'Query', OverallAnalytics: Array<{ __typename?: 'Analytics', Type: AnalyticsType, Title: string, SubTitle?: string | null, Highlight?: { __typename?: 'HighlightAnalytics', Value: string } | null, PieChart?: { __typename?: 'PieChartAnalytics', Values: Array<number>, Labels: Array<string> } | null, Quartile?: { __typename?: 'QuartileAnalytics', Min: number, Max: number, Average: number, Median: number, FirstQuartile: number, ThirdQuartile: number } | null }> };

export type GetVersionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVersionQuery = { __typename?: 'Query', Version: string };

export type GetProductsQueryVariables = Exact<{
  pageSize: Scalars['Int']['input'];
  pageOffset: Scalars['Int']['input'];
  conditions: Conditions;
}>;


export type GetProductsQuery = { __typename?: 'Query', Products: Array<{ __typename?: 'Product', Id: string, Name: string, SourceId: string, Description: string, Composition: string, Labels: Array<string>, Url: string, SKUs: Array<{ __typename?: 'ProductSKU', Id: string, SourceId: string, Price: number, OldPrice: number, Discount: number, Sizes?: Array<{ __typename?: 'ProductSKUSize', Size: string, ComingSoon: boolean }> | null, Images?: Array<{ __typename?: 'ProductSKUImage', Url: string, Color: string }> | null }>, Brand?: { __typename?: 'Brand', Id: string, Name: string } | null }> };


export const GetBrandAnalyticsDocument = gql`
    query GetBrandAnalytics($brandId: String!, $conditions: Conditions!) {
  BrandAnalytics(brandId: $brandId, conditions: $conditions) {
    Type
    Highlight {
      Value
    }
    PieChart {
      Values
      Labels
    }
    Quartile {
      Min
      Max
      Average
      Median
      FirstQuartile
      ThirdQuartile
    }
    Title
    SubTitle
  }
}
    `;

/**
 * __useGetBrandAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetBrandAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandAnalyticsQuery({
 *   variables: {
 *      brandId: // value for 'brandId'
 *      conditions: // value for 'conditions'
 *   },
 * });
 */
export function useGetBrandAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<GetBrandAnalyticsQuery, GetBrandAnalyticsQueryVariables> & ({ variables: GetBrandAnalyticsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBrandAnalyticsQuery, GetBrandAnalyticsQueryVariables>(GetBrandAnalyticsDocument, options);
      }
export function useGetBrandAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBrandAnalyticsQuery, GetBrandAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBrandAnalyticsQuery, GetBrandAnalyticsQueryVariables>(GetBrandAnalyticsDocument, options);
        }
export function useGetBrandAnalyticsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetBrandAnalyticsQuery, GetBrandAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBrandAnalyticsQuery, GetBrandAnalyticsQueryVariables>(GetBrandAnalyticsDocument, options);
        }
export type GetBrandAnalyticsQueryHookResult = ReturnType<typeof useGetBrandAnalyticsQuery>;
export type GetBrandAnalyticsLazyQueryHookResult = ReturnType<typeof useGetBrandAnalyticsLazyQuery>;
export type GetBrandAnalyticsSuspenseQueryHookResult = ReturnType<typeof useGetBrandAnalyticsSuspenseQuery>;
export type GetBrandAnalyticsQueryResult = Apollo.QueryResult<GetBrandAnalyticsQuery, GetBrandAnalyticsQueryVariables>;
export const GetBrandProductsDocument = gql`
    query GetBrandProducts($brandId: String!, $pageSize: Int!, $pageOffset: Int!, $conditions: Conditions!) {
  BrandProducts(
    brandId: $brandId
    pageSize: $pageSize
    pageOffset: $pageOffset
    conditions: $conditions
  ) {
    Id
    Name
    SourceId
    Description
    Composition
    Url
    Labels
    SKUs {
      Id
      SourceId
      Price
      OldPrice
      Discount
      Sizes {
        Size
        ComingSoon
      }
      Images {
        Url
        Color
      }
    }
  }
}
    `;

/**
 * __useGetBrandProductsQuery__
 *
 * To run a query within a React component, call `useGetBrandProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandProductsQuery({
 *   variables: {
 *      brandId: // value for 'brandId'
 *      pageSize: // value for 'pageSize'
 *      pageOffset: // value for 'pageOffset'
 *      conditions: // value for 'conditions'
 *   },
 * });
 */
export function useGetBrandProductsQuery(baseOptions: Apollo.QueryHookOptions<GetBrandProductsQuery, GetBrandProductsQueryVariables> & ({ variables: GetBrandProductsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBrandProductsQuery, GetBrandProductsQueryVariables>(GetBrandProductsDocument, options);
      }
export function useGetBrandProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBrandProductsQuery, GetBrandProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBrandProductsQuery, GetBrandProductsQueryVariables>(GetBrandProductsDocument, options);
        }
export function useGetBrandProductsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetBrandProductsQuery, GetBrandProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBrandProductsQuery, GetBrandProductsQueryVariables>(GetBrandProductsDocument, options);
        }
export type GetBrandProductsQueryHookResult = ReturnType<typeof useGetBrandProductsQuery>;
export type GetBrandProductsLazyQueryHookResult = ReturnType<typeof useGetBrandProductsLazyQuery>;
export type GetBrandProductsSuspenseQueryHookResult = ReturnType<typeof useGetBrandProductsSuspenseQuery>;
export type GetBrandProductsQueryResult = Apollo.QueryResult<GetBrandProductsQuery, GetBrandProductsQueryVariables>;
export const GetBrandsDocument = gql`
    query GetBrands {
  Brand {
    Id
    Name
  }
}
    `;

/**
 * __useGetBrandsQuery__
 *
 * To run a query within a React component, call `useGetBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBrandsQuery(baseOptions?: Apollo.QueryHookOptions<GetBrandsQuery, GetBrandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBrandsQuery, GetBrandsQueryVariables>(GetBrandsDocument, options);
      }
export function useGetBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBrandsQuery, GetBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBrandsQuery, GetBrandsQueryVariables>(GetBrandsDocument, options);
        }
export function useGetBrandsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetBrandsQuery, GetBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBrandsQuery, GetBrandsQueryVariables>(GetBrandsDocument, options);
        }
export type GetBrandsQueryHookResult = ReturnType<typeof useGetBrandsQuery>;
export type GetBrandsLazyQueryHookResult = ReturnType<typeof useGetBrandsLazyQuery>;
export type GetBrandsSuspenseQueryHookResult = ReturnType<typeof useGetBrandsSuspenseQuery>;
export type GetBrandsQueryResult = Apollo.QueryResult<GetBrandsQuery, GetBrandsQueryVariables>;
export const GetOverallAnalyticsDocument = gql`
    query GetOverallAnalytics {
  OverallAnalytics {
    Type
    Highlight {
      Value
    }
    PieChart {
      Values
      Labels
    }
    Quartile {
      Min
      Max
      Average
      Median
      FirstQuartile
      ThirdQuartile
    }
    Title
    SubTitle
  }
}
    `;

/**
 * __useGetOverallAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetOverallAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOverallAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOverallAnalyticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOverallAnalyticsQuery(baseOptions?: Apollo.QueryHookOptions<GetOverallAnalyticsQuery, GetOverallAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOverallAnalyticsQuery, GetOverallAnalyticsQueryVariables>(GetOverallAnalyticsDocument, options);
      }
export function useGetOverallAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOverallAnalyticsQuery, GetOverallAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOverallAnalyticsQuery, GetOverallAnalyticsQueryVariables>(GetOverallAnalyticsDocument, options);
        }
export function useGetOverallAnalyticsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetOverallAnalyticsQuery, GetOverallAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOverallAnalyticsQuery, GetOverallAnalyticsQueryVariables>(GetOverallAnalyticsDocument, options);
        }
export type GetOverallAnalyticsQueryHookResult = ReturnType<typeof useGetOverallAnalyticsQuery>;
export type GetOverallAnalyticsLazyQueryHookResult = ReturnType<typeof useGetOverallAnalyticsLazyQuery>;
export type GetOverallAnalyticsSuspenseQueryHookResult = ReturnType<typeof useGetOverallAnalyticsSuspenseQuery>;
export type GetOverallAnalyticsQueryResult = Apollo.QueryResult<GetOverallAnalyticsQuery, GetOverallAnalyticsQueryVariables>;
export const GetVersionDocument = gql`
    query GetVersion {
  Version
}
    `;

/**
 * __useGetVersionQuery__
 *
 * To run a query within a React component, call `useGetVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVersionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVersionQuery(baseOptions?: Apollo.QueryHookOptions<GetVersionQuery, GetVersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVersionQuery, GetVersionQueryVariables>(GetVersionDocument, options);
      }
export function useGetVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVersionQuery, GetVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVersionQuery, GetVersionQueryVariables>(GetVersionDocument, options);
        }
export function useGetVersionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetVersionQuery, GetVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetVersionQuery, GetVersionQueryVariables>(GetVersionDocument, options);
        }
export type GetVersionQueryHookResult = ReturnType<typeof useGetVersionQuery>;
export type GetVersionLazyQueryHookResult = ReturnType<typeof useGetVersionLazyQuery>;
export type GetVersionSuspenseQueryHookResult = ReturnType<typeof useGetVersionSuspenseQuery>;
export type GetVersionQueryResult = Apollo.QueryResult<GetVersionQuery, GetVersionQueryVariables>;
export const GetProductsDocument = gql`
    query GetProducts($pageSize: Int!, $pageOffset: Int!, $conditions: Conditions!) {
  Products(pageSize: $pageSize, pageOffset: $pageOffset, conditions: $conditions) {
    Id
    Name
    SourceId
    Description
    Composition
    Labels
    Url
    SKUs {
      Id
      SourceId
      Price
      OldPrice
      Discount
      Sizes {
        Size
        ComingSoon
      }
      Images {
        Url
        Color
      }
    }
    Brand {
      Id
      Name
    }
  }
}
    `;

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      pageOffset: // value for 'pageOffset'
 *      conditions: // value for 'conditions'
 *   },
 * });
 */
export function useGetProductsQuery(baseOptions: Apollo.QueryHookOptions<GetProductsQuery, GetProductsQueryVariables> & ({ variables: GetProductsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
      }
export function useGetProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
        }
export function useGetProductsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
        }
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>;
export type GetProductsLazyQueryHookResult = ReturnType<typeof useGetProductsLazyQuery>;
export type GetProductsSuspenseQueryHookResult = ReturnType<typeof useGetProductsSuspenseQuery>;
export type GetProductsQueryResult = Apollo.QueryResult<GetProductsQuery, GetProductsQueryVariables>;