import { startCase, lowerCase } from "lodash";
import { ProductSku } from "../generated/graphql";

export function toTitleCase(title: string) {
    return title.replace("-", " ").split(" ").map(word => startCase(lowerCase(word))).join(" ");
}

export function isNumeric(str?: string) {
    return !isNaN(Number(str));
}

export function formatNumberWithK(num: number) {
    return Math.abs(num) > 999 ? Math.sign(num)*Number.parseFloat((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
}

export function setCookie(name: string, value: string, days: number) {
    let expires = "";
    if (days != null) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function formatToIndianNumbering(num: number): string {
    if (num >= 10000000) {
        return (num / 10000000).toFixed(2) + 'Cr';
    } else if (num >= 100000) {
        return (num / 100000).toFixed(2) + 'L';
    } else if (num >= 1000) {
        return (num / 1000).toFixed(2) + 'k';
    } else {
        return num.toString();
    }
}

const sizeMap = {
    xl: '480',
    lg: '320',
    md: '240',
    sm: '80', 
};

export function getImageUrl(url: string, size: 'xl' | 'lg' | 'md' | 'sm' = "md") {
    if (url.startsWith("//")) {
        url = `https:${url}`;
    }
    if (url.startsWith("https://apisap.fabindia.com")) {
        return url;
    }
    const newSize = sizeMap[size];
    if (url.startsWith("https://in.benetton.com")) {
        return url.replace("_20x", `_${newSize}x`);
    }
    return url.replace(/(\d+)x/g, (_match, _p1) => `${newSize}x`)
      .replace(/w=(\d+)/, `w=${newSize}`)
      .replace(/w_(\d+)/, `w_${newSize}`)
      .replace(/(\d+)x(\d+)/, `${newSize}x${newSize}`);
}

export function getFirstImageUrl(skus: ProductSku[]) {
    for (const sku of skus) {
        for (const image of sku.Images ?? []) {
            if (image.Url != null && image.Url.length > 0) {
                return image.Url;
            }
        }
    }
    return null;
}