import { FC, useMemo } from "react";
import { ClassNames } from "../classes";
import classNames from "classnames";
import { Tooltip } from "../tooltip";
import { BG_COLORS } from "./common";

type IVerticalDistributionChartProps = {
    data: { name: string, value: number }[];
}

export const VerticalDistributionChart: FC<IVerticalDistributionChartProps> = ({ data }) => {
    const maxValue = useMemo(() => {
        return Math.max(...data.map(datum => datum.value));
    }, [data]);

    return <div className="w-full h-full flex flex-col my-2">
        {
            data.map((datum, index) => (
                <div className="flex bg-black/5 dark:bg-white/5">
                    <div className={classNames(ClassNames.Text, "py-2 px-4 w-1/3")}>{datum.name}</div>
                    <div className="h-full w-2/3">
                        <Tooltip tooltip={datum.value}>
                            <div className="w-full py-2 px-4 bg-black/5 dark:bg-white/5 flex justify-center items-center hover:bg-black/10">
                                <div className={classNames("rounded-full h-4 w-4", BG_COLORS[index % BG_COLORS.length])} style={{
                                    transform: `scale(${datum.value/maxValue})`,
                                }} />
                            </div>
                        </Tooltip>
                    </div>
                </div>
            ))
        }
    </div>
}