import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { FC, ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { IInternalRoute, InternalRoutes } from "../config/routes";
import { useAppSelector } from "../store/hooks";
import { Breadcrumb } from "./breadcrumbs";
import { ClassNames } from "./classes";
import { Icons } from "./icons";
import { Logo } from "./logo";
import { useDispatch } from "react-redux";
import { GlobalActions } from "../store/global";

type IPageProps = {
    wrapperClassName?: string;
    className?: string;
    children: ReactNode;
}

export const Page: FC<IPageProps> = (props) => {
    return <div className={twMerge("flex grow px-8 py-6 flex-col h-full w-full bg-white dark:bg-black/90", props.wrapperClassName)}>
        <AnimatePresence>
            <motion.div className={twMerge("flex flex-row grow flex-wrap gap-2 w-full h-full overflow-y-auto", props.className)}
                initial={{ opacity: 0 }}
                animate={{ opacity: 100, transition: { duration: 0.5 } }}
                exit={{ opacity: 0 }}>
                    {props.children}
            </motion.div>
        </AnimatePresence>
    </div>
}

type IInternalPageProps = IPageProps & {
    children: ReactNode;
    routes?: IInternalRoute[];
}

export const InternalPage: FC<IInternalPageProps> = (props) => {
    const pathname = useLocation().pathname;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const comparingProducts = useAppSelector(state => state.global.comparingProducts);
    const comparingBrands = useAppSelector(state => state.global.comparingBrands);
    const darkModeEnabled = useAppSelector(state => state.global.theme === "dark");

    return (
        <div className="flex grow h-full w-full">
            <Page wrapperClassName="p-0" {...props}>
                {/* <SideBar /> */}
                <div className="flex flex-col grow pt-4">
                    <div className="flex justify-between items-center px-8">
                        <div className="sticky z-10 top-2 left-4 w-fit rounded-xl py-2 transition-all flex gap-12 items-center">
                            <div className="flex gap-1 items-center">
                                <div className="w-8"><Logo /></div> 
                                <div className={classNames(ClassNames.Title, "text-lg")}>
                                    Nano Knot
                                </div>
                            </div>
                            <Breadcrumb routes={props.routes ?? []} active={props.routes?.at(-1)} />
                        </div>
                        <div className="flex gap-8 items-center">
                            <div className={classNames(ClassNames.OutlinedButton, "items-center hover:scale-110")} onClick={() => dispatch(GlobalActions.setTheme(darkModeEnabled ? "light" : "dark"))}>
                                {darkModeEnabled ? Icons.Moon : Icons.Sun}
                            </div>
                            <button className={twMerge(classNames(ClassNames.TitledButton, "relative text-sm", {
                                "text-teal-600 dark:text-teal-500": pathname === InternalRoutes.Brands.Analytics.path,
                            }))} onClick={() => navigate(InternalRoutes.Brands.Analytics.path)}>
                                {Icons.Analytics}
                                Brand analytics
                                <AnimatePresence mode="wait">
                                    {
                                        comparingBrands.length > 0 &&
                                        <motion.div key="compare-brands" className="absolute top-0 -right-4 w-5 h-5 bg-green-700 dark:bg-green-500/20 text-neutral-300 rounded-full text-xs flex items-center justify-center" initial={{ y: 10, scale: 0.5, opacity: 0 }}
                                                    animate={{ y: -10, scale: 1, opacity: 1 }}
                                                    exit={{ y: 10, scale: 0.5, opacity: 0 }}>
                                            {comparingBrands.length > 99 ? "99+" : comparingBrands.length}
                                        </motion.div>
                                    }
                                </AnimatePresence>
                            </button>
                            <button className={twMerge(classNames(ClassNames.TitledButton, "relative text-sm", {
                                "text-teal-600 dark:text-teal-500": pathname === InternalRoutes.Product.Compare.path,
                            }))} onClick={() => navigate(InternalRoutes.Product.Compare.path)}>
                                {Icons.Compare}
                                Compare products
                                <AnimatePresence mode="wait">
                                    {
                                        comparingProducts.length > 0 &&
                                        <motion.div key="compare-products" className="absolute top-0 -right-4 w-5 h-5 bg-green-700 dark:bg-green-500/20 text-neutral-300 rounded-full text-xs flex items-center justify-center" initial={{ y: 10, scale: 0.5, opacity: 0 }}
                                                    animate={{ y: -10, scale: 1, opacity: 1 }}
                                                    exit={{ y: 10, scale: 0.5, opacity: 0 }}>
                                            {comparingProducts.length > 99 ? "99+" : comparingProducts.length}
                                        </motion.div>
                                    }
                                </AnimatePresence>
                            </button>
                            <button className={classNames(ClassNames.TitledButton, "text-sm")} onClick={() => navigate(InternalRoutes.Logout.path)}>
                                {Icons.Logout}
                                Logout
                            </button>
                        </div>
                    </div>
                    <div className="flex grow flex-wrap gap-2 py-4 content-start relative px-8">
                        {props.children}
                    </div>
                </div>
            </Page>
        </div>
    )
}