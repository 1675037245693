import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { isArray } from "lodash";
import { cloneElement, FC, useCallback, useMemo, useState } from "react";
import { ClassNames } from "./classes";
import { Icons } from "./icons";

export type IDropdownItem = { id: string, label: string };

export const Dropdown: FC<{
    placeholder?: string,
    selectedId?: string | string[],
    items: IDropdownItem[];
    onChange?: (itemIds: string[]) => void;
    multiple?: boolean,
    className?: string,
}> = ({ placeholder, items = [], onChange, selectedId = [], multiple = false, className }) => {
    const [selected, setSelected] = useState<string[]>(
        isArray(selectedId) ? selectedId : [selectedId]
    );
    const [open, setOpen] = useState(false);

    const handleOpen = useCallback(() => {
        setOpen(o => !o);
    }, []);

    const handleItemClick = useCallback((item: IDropdownItem) => {
        setSelected(prevSelected => {
            let newSelected;
            
            if (multiple) {
                if (prevSelected.includes(item.id)) {
                    newSelected = prevSelected.filter(id => id !== item.id);
                } else {
                    newSelected = [...prevSelected, item.id];
                }
            } else {
                newSelected = [item.id];
                setOpen(false); 
            }
            
            onChange?.(newSelected); 
            return newSelected;
        });
    }, [multiple, onChange]);

    const selectedSet = useMemo(() => new Set(selected), [selected]);

    return (
        <div className="relative inline-block text-left">
            <div>
                <button
                    type="button"
                    className={classNames(ClassNames.Button, className)}
                    id="menu-button"
                    aria-expanded="true"
                    aria-haspopup="true"
                    onClick={handleOpen}
                >
                    <span>{placeholder}</span>
                    {selectedSet.size > 0 && <span>({selectedSet.size})</span>}
                    {Icons.DownChevron}
                </button>
            </div>
            <AnimatePresence mode="wait">
                {open && (
                    <>
                        <div
                            className="fixed inset-0 z-10"
                            onClick={() => setOpen(false)}
                        />
                        <motion.div
                            initial={{ opacity: 0, scale: 0.95 }}
                            animate={{ opacity: 100, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.95 }}
                            className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md border-[1px] border-black/5 dark:border-white/5 dark:bg-black/50 backdrop-blur-lg dark:shadow-black shadow-lg ring-opacity-5 focus:outline-none max-h-[300px] overflow-y-scroll"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="menu-button"
                            tabIndex={-1}
                        >
                            <div className="py-1" role="none">
                                {items.map(item => (
                                    <div
                                        key={item.id}
                                        className="relative group/dropdown-item"
                                    >
                                        <div
                                            className={classNames(
                                                ClassNames.Button,
                                                "block px-4 py-2 hover:pl-6 transition-all"
                                            )}
                                            role="menuitem"
                                            tabIndex={-1}
                                            id={`menu-item-${item.id}`}
                                            onClick={() => handleItemClick(item)}
                                        >
                                            {item.label}
                                        </div>
                                        <div
                                            className={classNames(
                                                "absolute top-1/2 -translate-y-1/2 right-2 group-hover/dropdown-item:backdrop-blur-lg rounded-full text-neutral-300 hidden",
                                                {
                                                    "!flex": selectedSet.has(item.id),
                                                }
                                            )}
                                        >
                                            {cloneElement(Icons.CheckCircle, {
                                                className: "h-4 w-4",
                                            })}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </motion.div>
                    </>
                )}
            </AnimatePresence>
        </div>
    );
};
